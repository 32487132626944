<template>
  <b-modal
    id="add-or-update-event-center"
    :visible="isAddOrUpdateActive"
    centered
    hide-footer
    hide-header
    @hidden="resetForm"
    @hide="resetForm"
    @change="(val) => $emit('update:is-add-or-update-active', val)"
  >
    <template #default="{ hide }">
      <b-overlay :show="loading" class="px-2">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header py-1" style="border-bottom: 1px solid #000000;">
          <h5 class="mb-0" style="font-weight: 400; font-size: 14px; line-height: 160%;">
            {{ isUpdate ? 'Update' : 'Add New' }}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="update_modal_form"
        >
          <b-form
            class="my-1"
            @submit.prevent="handleSubmit(onCreateOrUpdate)"
            @reset.prevent="resetForm"
          >

            <b-alert
              v-if="errorMessage"
              variant="danger"
              :show="true"
            >
              <div class="alert-body font-small-2">
                <b-card-text class="mb-0">
                  {{ errorMessage }}
                </b-card-text>
              </div>
            </b-alert>
            
            <validation-provider
              #default="validationContext"
              name="Name of skill"
              rules="required"
            >
              <b-form-group
                label="Name of skill"
                label-for="name"
              >
                <template #label>
                  <span>Name of skill<sup style="color: tomato">*</sup></span>
                </template>

                <b-form-input
                  id="name"
                  v-model="new_data.name"
                  autofocus
                  trim
                  placeholder="Ex: Problem solving"
                  :disabled="disableForm"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="disableForm"
              >
                {{ isUpdate ? 'Update' : 'Add' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import {
  BOverlay, BSidebar, BForm, BFormGroup, BFormInput, 
  BFormTextarea, BFormInvalidFeedback, BButton, BFormSelect,
  BAlert, BCardText, BModal, BFormCheckbox, BFormDatepicker
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { get, cloneDeep, omit } from 'lodash';
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

const rawFormData = {
  name: "",
}

export default {
  components: {
    BForm,
    BAlert,
    BModal,
    BButton,
    vSelect,
    BOverlay,
    BSidebar,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BFormCheckbox,
    BFormDatepicker,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddOrUpdateActive',
    event: 'update:is-add-or-update-active',
  },
  props: {
    isAddOrUpdateActive: {
      type: Boolean,
      required: true,
    },
    updateData: {
      type: Object,
      default: null,
      required: false
    },
  },
  data() {
    return {
      required,
      loading: false,
      errorMessage: "",
      new_data: cloneDeep(rawFormData),
    }
  },
  computed: {
    isUpdate(){
      return !!this.updateData
    },
    disableForm(){
      return false
    },
  },
  watch: {
    updateData: {
      handler(updateData){
        if (updateData){
          this.new_data = {
            _id: updateData._id,
            name: updateData.name
          }
        }
      },
      immediate: true,
      deep: true
    },
  },
  methods: {
    async onCreateOrUpdate(){
      try {
        this.loading = true;
        this.errorMessage = "";

        const success = await this.$refs.update_modal_form.validate();
        if (!success){
          return;
        }

        const messages = {
          update: {
            title: "Confirm Update",
            text: "Proceed to update record?",
            icon: "warning"
          },
          new: {
            title: "Create",
            text: "Proceed to create new record?",
            icon: "info"
          }
        }

        /* eslint-disable no-nested-ternary */
        const message = this.isUpdate ? messages.update : messages.new;
        const { title, text, icon } = message;

        const result = await this.$swal({
          title,
          text,
          icon,
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });
        if (!result.isConfirmed) {
          return;
        }
        
        if (this.isUpdate){
          const id = this.new_data._id
          const update_data = omit(this.new_data, "_id");
          await this.useJwt().clientService.updateSkill(id, update_data);
        } else {
          await this.useJwt().clientService.createSkill(this.new_data);
        }
        this.$emit("refetchData");
        this.$emit('update:is-add-or-update-active', false);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'AlertQuestionIcon',
            variant: 'success',
            text: `${this.isUpdate ? 'Updated' : 'Added'} successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    resetForm() {
      this.$emit("resetUpdateData")
      this.new_data = {};
      if (this.$refs.update_modal_form) {
        this.$refs.update_modal_form.reset()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-or-update-event-center {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
